import './index.html';
import './style.scss';
import {gsap} from "gsap";
import CustomEase from "gsap/CustomEase";
import {debounce} from "lodash";

export default async (header) => {
  const framesPath3d = window.template_directory + '/front-end/src/blocks/header_block/new-logo-animation/logomark_';
  const framesNumber3d = 226;
  const canvasWrappers3d = header.querySelectorAll('.canvas-wrapper.three-d');
  const canvases3d = header.querySelectorAll('.canvas-wrapper.three-d canvas');
  const contexts3d = [];
  for (let i = 0; i < canvasWrappers3d.length; i++) {
    const {width, height} = canvasWrappers3d[i].getBoundingClientRect();
    canvases3d[i].width = width;
    canvases3d[i].height = height;
    contexts3d.push(canvases3d[i].getContext("2d"))
  }
  const framesPath = window.template_directory+'/front-end/src/blocks/header_block/menu-button-animation/Animation_';
  const framesNumber = 226;
  const canvasWrappers = header.querySelectorAll('.canvas-wrapper.btn');
  const canvases = header.querySelectorAll('.canvas-wrapper.btn canvas');
  const contexts = [];
  for (let i = 0; i < canvasWrappers.length; i++) {
    const {width, height} = canvasWrappers[i].getBoundingClientRect();
    canvases[i].width = width;
    canvases[i].height = height;
    contexts.push(canvases[i].getContext("2d"))
  }
  const currentFrame = (index,path) => (
    `${path}${(index).toString().padStart(5, '0')}.png`
  );

  const images3d = []
  const frame3d = {
    current: 0
  };

  const indexLoad3d = {current: 0}

  for (let i = 0; i < framesNumber3d; i++) {
    const img = new Image();
    img.dataset.src = currentFrame(i,framesPath3d);
    images3d.push(img);
  }

  const loadNextImages3d = () => {
    if (indexLoad3d.current === images3d.length) return;
    render3d()
    images3d[indexLoad3d.current].addEventListener('load', loadNextImages3d)
    images3d[indexLoad3d.current].src = images3d[indexLoad3d.current].dataset.src;
    indexLoad3d.current++
  }
  for (let i = 0; i < 6; i++) {
    loadNextImages3d();
  }


  const fixCanvas3d = debounce(() => {
    for (let i = 0; i < canvasWrappers3d.length; i++) {
      const {width, height} = canvasWrappers3d[i].getBoundingClientRect();
      canvases3d[i].width = width;
      canvases3d[i].height = height;
    }
    render3d();
  }, 1000)

  window.addEventListener('resize', fixCanvas3d)


  const tl3d = gsap.to(frame3d, {
    current: framesNumber3d - 1,
    snap: "current",
    ease: 'linear',
    duration: 10, //todo get the video duration form Yasser
    onUpdate: render3d,
    repeat: -1,
    // yoyo: true,
  });


  images3d[0].onload = () => {
    render3d();
  }
  let isLoading3d = true;
  let isMenuOpened = false;
  let isLoading = true;

  const images = []
  const frame = {
    current: 0
  };

  const indexLoaded = {current: 0}

  for (let i = 0; i < framesNumber; i+=2) {
    const img = new Image();
    img.dataset.src = currentFrame(i,framesPath);
    images.push(img);
  }

  const loadNextImages = () => {
    if (indexLoaded.current === images.length) return;
    render()
    images[indexLoaded.current].addEventListener('load', loadNextImages)
    images[indexLoaded.current].src = images[indexLoaded.current].dataset.src;
    indexLoaded.current++
  }
  for (let i = 0; i < 6; i++) {
    loadNextImages();
  }


  const fixCanvas = debounce(() => {
    for (let i = 0; i < canvasWrappers.length; i++) {
      const {width, height} = canvasWrappers[i].getBoundingClientRect();
      canvases[i].width = width;
      canvases[i].height = height;
    }
    render();
  }, 1000)

  window.addEventListener('resize', fixCanvas)


  const tl = gsap.to(frame, {
    current: framesNumber - 1,
    snap: "current",
    ease: 'linear',
    duration: 14.3, //todo get the video duration form Yasser
    onUpdate: render,
    repeat: -1,
    // yoyo: true,
  });


  images[0].onload = () => {
    render();
  }

  function drawLoading() {
    // console.log(isLoading3d,isMenuOpened);
    tl3d.paused(isLoading3d || !isMenuOpened);
    requestAnimationFrame(drawLoading)
  }

  requestAnimationFrame(drawLoading)

  function render3d() {
    for (const context of contexts3d) {
      drawImageScaled3d(images3d[frame3d.current], context, true)
    }
  }

  function drawImageScaled3d(img, ctx, preserveAspectRatio = true) {

    if (!img.src || !img.complete) {
      isLoading3d = true;
      return;
    }
    const canvas = ctx.canvas;
    isLoading3d = false
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (preserveAspectRatio) {
      const hRatio = canvas.width / img.naturalWidth;
      const vRatio = canvas.height / img.naturalHeight;
      const ratio = Math.min(hRatio, vRatio);
      const dw = img.naturalWidth * ratio;
      const dh = img.naturalHeight * ratio;
      const dx = (canvas.width - dw) / 2;
      const dy = (canvas.height - dh) / 2;
      // ctx.fillStyle = 'red';
      // ctx.fillRect(dx, dy, dw, dh);
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight,
        dx, dy, dw, dh);
      // for (let i = 0; i < 11; i++) {
      //   for (let j = 0; j < 11; j++) {
      //     ctx.beginPath();
      //     ctx.arc(dx+i*dw/10, dy+j*dh/10, 3, 0, 2 * Math.PI, false);
      //     ctx.fillStyle = 'green';
      //     ctx.fill();
      //     ctx.lineWidth = 0;
      //   }
      // }
    } else {
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, canvas.width, canvas.height);
    }

  }


  function render() {
    for (const context of contexts) {
      drawImageScaled(images[~~(frame.current/2)], context, false)
    }
  }

  function drawImageScaled( img, ctx,preserveAspectRatio = true) {

    if (!img.src || !img.complete) {
      isLoading = true;
      return;
    }
    const canvas = ctx.canvas;
    isLoading = false
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (preserveAspectRatio) {
      const hRatio = canvas.width / img.naturalWidth;
      const vRatio = canvas.height / img.naturalHeight;
      const ratio = Math.min(hRatio, vRatio);
      const dw = img.naturalWidth * ratio;
      const dh = img.naturalHeight * ratio;
      const dx = (canvas.width - dw) / 2;
      const dy = (canvas.height - dh) / 2;
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight,
        dx, dy, dw, dh);
    } else {
      ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, canvas.width, canvas.height);
    }

  }


  const btnMenu = header.querySelector(".menu-btn")
  const menuList = header.querySelector(".menu-list")
  const menuListIcon = header.querySelector(".social-media-icon")
  const linkWrapper = header.querySelector(".links-wrapper")
  const menuShape = header.querySelector(".menu-shape")

  const menuOpen = gsap.timeline({
    paused: true
  })
  menuOpen
    .to(menuList, {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
      duration: 1,
      ease: CustomEase.create("custom", "M0,0,C1.07,0.093,0.432,0.962,0.998,0.996,0.999,0.996,0.999,0.998,1,1")
    })
    .from(linkWrapper.children, {
      opacity: 0,
    }, "<70%")
    .from(menuListIcon, {
      x: "-10vw",
      opacity: 0
    }, "<")
    .from(menuShape, {
      opacity: 0
    }, ">-10%")
    .addPause('closeHeader')
    .to(menuShape, {
      opacity: 0
    })
    .to(linkWrapper.children, {
      opacity: 0,
    }, "<10%")
    .to(menuListIcon, {
      x: "10vw",
      opacity: 0
    }, "<")
    .to(menuList, {
      clipPath: "polygon(100% 0, 100% 0, 100% 100%, 100% 100%)",
      duration: 1,
      ease: CustomEase.create("custom", "M0,0,C1.07,0.093,0.432,0.962,0.998,0.996,0.999,0.996,0.999,0.998,1,1")
    })

  btnMenu.addEventListener("click", function () {
    const isActive = header.querySelector(".menu-list").classList.toggle("open");
    if (isActive) {
      header.classList.add('active');
      menuOpen.play(0);
      setTimeout(()=>{
        btnMenu.querySelector('span').textContent = 'Close';
      },1000)
      isMenuOpened = true;
    } else {
      header.classList.remove('active');
      menuOpen.play('closeHeader');
      setTimeout(()=>{
        btnMenu.querySelector('span').textContent = 'Menu';
      },1000)
      isMenuOpened = false;
    }
  })
};

