export function headerSticky(container = document) {
  const header = document.querySelector('header');
  const top = window.pageYOffset || document.documentElement.scrollTop
  const fireSticky = function () {
    if (top >= 10) {
      header.classList.toggle('sticky');
    }
    header.classList.toggle('sticky', window.scrollY >= 10);
  };
  window.addEventListener('scroll', fireSticky);
  if (document.readyState === 'complete') {
    fireSticky();
  }
}
