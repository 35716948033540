import './styles/style.scss';
import {gsap} from 'gsap';
import header from './blocks/header_block';
import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {barba} from "./scripts/general/barba";
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {headerSticky} from "./scripts/general/header-sticky";

const reInvokableFunction = async (container = document) => {
  container.querySelector('header') && await header(container.querySelector('header'));
  await initBlocks(container);
  container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));
  getHeightOfViewPort(container);
  headerSticky(container);
};
let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });
  if (document.readyState === 'complete' && !loaded) {
    loaded = true;
    await reInvokableFunction();
    barba(reInvokableFunction);
    document.body.classList.add('loaded');
  }
}

onLoad();

document.onreadystatechange = function () {
  onLoad();
};
