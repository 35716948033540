import './index.html';
import './style.scss';

import * as PIXI from 'pixi.js';
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur';
import SimplexNoise from 'simplex-noise';
import hsl from 'hsl-to-hex';

// import {gsap} from 'gsap';
import {debounce} from "lodash";

const heroBlock = async (block) => {
  // const framesPath = window.template_directory+'/front-end/src/blocks/hero_block/gradient-animation-better/Animation_';
  // const framesNumber = 226;
  // const canvasWrappers = block.querySelectorAll('.canvas-wrapper');
  // const canvases = block.querySelectorAll('canvas');
  // const contexts = [];
  // for (let i = 0; i < canvasWrappers.length; i++) {
  //   const {width, height} = canvasWrappers[i].getBoundingClientRect();
  //   canvases[i].width = width;
  //   canvases[i].height = height;
  //   contexts.push(canvases[i].getContext("2d"))
  // }
  // const currentFrame = index => (
  //   `${framesPath}${(index).toString().padStart(5, '0')}.png`
  // );
  //
  // const images = []
  // const frame = {
  //   current: 0
  // };
  //
  // const index = {current: 0}
  //
  // for (let i = 0; i < framesNumber; i++) {
  //   const img = new Image();
  //   img.dataset.src = currentFrame(i);
  //   images.push(img);
  // }
  //
  // const loadNextImages = () => {
  //   if (index.current === images.length) return;
  //   render()
  //   images[index.current].addEventListener('load', loadNextImages)
  //   images[index.current].src = images[index.current].dataset.src;
  //   index.current++
  // }
  // for (let i = 0; i < 6; i++) {
  //   loadNextImages();
  // }
  //
  //
  // const fixCanvas = debounce(() => {
  //   for (let i = 0; i < canvasWrappers.length; i++) {
  //     const {width, height} = canvasWrappers[i].getBoundingClientRect();
  //     canvases[i].width = width;
  //     canvases[i].height = height;
  //   }
  //   render();
  // }, 1000)
  //
  // window.addEventListener('resize', fixCanvas)
  //
  //
  // const tl = gsap.to(frame, {
  //   current: framesNumber - 1,
  //   snap: "current",
  //   ease: 'linear',
  //   duration: 14.3, //todo get the video duration form Yasser
  //   onUpdate: render,
  //   repeat: -1,
  //   // yoyo: true,
  // });
  //
  //
  // images[0].onload = () => {
  //   render();
  // }
  // const smallCircle = {
  //   radius: 30,
  //   speed: 2
  // }
  // let isLoading = true;
  // let progress = 0;
  //
  // function accelerateInterpolator(x) {
  //   return x * x;
  // }
  //
  // function decelerateInterpolator(x) {
  //   return 1 - ((1 - x) * (1 - x));
  // }
  //
  // function drawCircle(circle, progress) {
  //   for (let context of contexts) {
  //     const canvas = context.canvas;
  //     context.clearRect(0, 0, canvas.width, canvas.height);
  //     context.fillStyle = 'transparent'
  //     context.beginPath();
  //     const start = accelerateInterpolator(progress) * circle.speed;
  //     const end = decelerateInterpolator(progress) * circle.speed;
  //     context.arc(canvas.width / 2, canvas.height / 2, circle.radius, (start - 0.5) * Math.PI, (end - 0.5) * Math.PI);
  //     context.lineWidth = 3;
  //     context.strokeStyle = "white";
  //     context.fill();
  //     context.stroke();
  //   }
  // }
  //
  // function drawLoading() {
  //   tl.paused(isLoading);
  //   requestAnimationFrame(drawLoading)
  // }
  //
  // requestAnimationFrame(drawLoading)
  //
  // function render() {
  //   for (const context of contexts) {
  //     drawImageScaled(images[frame.current], context, false)
  //   }
  // }
  //
  // function drawImageScaled( img, ctx,preserveAspectRatio = true) {
  //
  //   if (!img.src || !img.complete) {
  //     isLoading = true;
  //     return;
  //   }
  //   const canvas = ctx.canvas;
  //   isLoading = false
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   if (preserveAspectRatio) {
  //     const hRatio = canvas.width / img.naturalWidth;
  //     const vRatio = canvas.height / img.naturalHeight;
  //     const ratio = Math.min(hRatio, vRatio);
  //     const dw = img.naturalWidth * ratio;
  //     const dh = img.naturalHeight * ratio;
  //     const dx = (canvas.width - dw) / 2;
  //     const dy = (canvas.height - dh) / 2;
  //     // ctx.fillStyle = 'red';
  //     // ctx.fillRect(dx, dy, dw, dh);
  //     ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight,
  //       dx, dy, dw, dh);
  //     // for (let i = 0; i < 11; i++) {
  //     //   for (let j = 0; j < 11; j++) {
  //     //     ctx.beginPath();
  //     //     ctx.arc(dx+i*dw/10, dy+j*dh/10, 3, 0, 2 * Math.PI, false);
  //     //     ctx.fillStyle = 'green';
  //     //     ctx.fill();
  //     //     ctx.lineWidth = 0;
  //     //   }
  //     // }
  //   } else {
  //     ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, canvas.width, canvas.height);
  //   }
  //
  // }


// return a random number within a range
  function random(min, max) {
    return Math.random() * (max - min) + min;
  }

// map a number from 1 range to another
  function map(n, start1, end1, start2, end2) {
    return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
  }

// Create a new simplex noise instance
  const simplex = new SimplexNoise();

// ColorPalette class
  class ColorPalette {
    constructor() {
      this.setColors();
      this.setCustomProperties();
    }

    setColors() {
      // pick a random hue somewhere between 220 and 360
      this.hue = ~~random(220, 360);
      this.complimentaryHue1 = this.hue + 30;
      this.complimentaryHue2 = this.hue + 60;
      // define a fixed saturation and lightness
      this.saturation = 95;
      this.lightness = 50;

      // define a base color
      this.baseColor = hsl(this.hue, this.saturation, this.lightness);
      // define a complimentary color, 30 degress away from the base
      this.complimentaryColor1 = hsl(
        this.complimentaryHue1,
        this.saturation,
        this.lightness
      );
      // define a second complimentary color, 60 degrees away from the base
      this.complimentaryColor2 = hsl(
        this.complimentaryHue2,
        this.saturation,
        this.lightness
      );

      // store dthe color choices in an array so that a random one can be picked later
      this.colorChoices = [
        // this.baseColor,
        // this.complimentaryColor1,
        // this.complimentaryColor2
        hsl(38, 67, 43),
        hsl(38, 67, 43),
        hsl(300, 96, 65)
      ];
    }

    randomColor() {
      // pick a random color
      return this.colorChoices[~~random(0, this.colorChoices.length)].replace(
        "#",
        "0x"
      );
    }
    color(i) {
      // pick a random color
      return this.colorChoices[i].replace("#", "0x");
    }

    setCustomProperties() {
      // set CSS custom properties so that the colors defined here can be used throughout the UI
      document.documentElement.style.setProperty("--hue", this.hue);
      document.documentElement.style.setProperty(
        "--hue-complimentary1",
        this.complimentaryHue1
      );
      document.documentElement.style.setProperty(
        "--hue-complimentary2",
        this.complimentaryHue2
      );
    }
  }

// Orb class
  class Orb {
    // Pixi takes hex colors as hexidecimal literals (0x rather than a string with '#')
    constructor(fill = 0x000000, bounds) {
      // bounds = the area an orb is "allowed" to move within

      this.bounds = bounds || Orb.setBounds();
      // initialise the orb's { x, y } values to a random point within it's bounds
      this.x = random(this.bounds["x"].min, this.bounds["x"].max);
      this.y = random(this.bounds["y"].min, this.bounds["y"].max);

      // how large the orb is vs it's original radius (this will modulate over time)
      this.scale = 1;

      // what color is the orb?
      this.fill = fill;

      // the original radius of the orb, set relative to window height
      this.radius = random(window.innerHeight / 4, window.innerHeight / 3);

      // starting points in "time" for the noise/self similar random values
      this.xOff = random(0, 1000);
      this.yOff = random(0, 1000);
      // how quickly the noise/self similar random values step through time
      this.inc = 0.0015;

      // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
      this.graphics = new PIXI.Graphics();
      this.graphics.alpha = 0.8;

      // 250ms after the last window resize event, recalculate orb positions.
      window.addEventListener(
        "resize",
        debounce(() => {
          this.bounds = this.setBounds();
        }, 250)
      );
    }

    static setBounds(y = 0.9, x = 0.75) {
      // how far from the { x, y } origin can each orb move
      const maxDist = window.innerWidth / 7;
      // the { x, y } origin for each orb (the bottom right of the screen)
      const originX = window.innerWidth * x;
      const originY = window.innerHeight * y;

      // allow each orb to move x distance away from it's x / y origin
      return {
        x: {
          min: originX - maxDist,
          max: originX + maxDist
        },
        y: {
          min: originY - maxDist * 0.65,
          max: originY + maxDist * 0.65
        }
      };
    }

    update() {
      // self similar "psuedo-random" or noise values at a given point in "time"
      const xNoise = simplex.noise2D(this.xOff, this.xOff);
      const yNoise = simplex.noise2D(this.yOff, this.yOff);
      const scaleNoise = simplex.noise2D(this.xOff, this.yOff);

      // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
      this.x = map(xNoise, -1, 1, this.bounds["x"].min, this.bounds["x"].max);
      this.y = map(yNoise, -1, 1, this.bounds["y"].min, this.bounds["y"].max);
      // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
      this.scale = map(scaleNoise, -1, 1, 0.7, 1);

      // step through "time"
      this.xOff += this.inc;
      this.yOff += this.inc;
    }

    render() {
      // update the PIXI.Graphics position and scale values
      this.graphics.x = this.x;
      this.graphics.y = this.y;
      this.graphics.scale.set(this.scale);

      // clear anything currently drawn to graphics
      this.graphics.clear();

      // tell graphics to fill any shapes drawn after this with the orb's fill color
      this.graphics.beginFill(this.fill);
      // draw a circle at { 0, 0 } with it's size set by this.radius
      this.graphics.drawCircle(0, 0, this.radius);
      // let graphics know we won't be filling in any more shapes
      this.graphics.endFill();
    }
  }

// Create PixiJS app
  const app = new PIXI.Application({
    // render to <canvas class="orb-canvas"></canvas>
    view: document.querySelector(".orb-canvas"),
    // auto adjust size to fit the current window
    resizeTo: window,
    // transparent background, we will be creating a gradient background later using CSS
    transparent: true
  });

// Create colour pdalette
  const colorPalette = new ColorPalette();
  const colorPaletteBlue = new ColorPalette();
  colorPaletteBlue.colorChoices = [
    hsl(255, 94, 55),
    hsl(300, 96, 65),
    hsl(300, 96, 65)
  ];
  app.stage.filters = [new KawaseBlurFilter(20, 10, true)];

// Create orbs
  const orbs = [];

  for (let i = 0; i < 3; i++) {
    // const orb = new Orb(colorPalette.randomColor());
    const orb = new Orb(colorPalette.color(i), Orb.setBounds(0.85, 0.9));
    app.stage.addChild(orb.graphics);
    orbs.push(orb);
  }

  for (let i = 0; i < 3; i++) {
    // const orb = new Orb(
    //   colorPaletteBlue.randomColor(),
    //   Orb.setBounds(0.15, 0.15)
    // );
    const orb = new Orb(colorPaletteBlue.color(i), Orb.setBounds(0.15, .05));
    app.stage.addChild(orb.graphics);
    orbs.push(orb);
  }
// Animate!
  if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
    app.ticker.add(() => {
      orbs.forEach((orb) => {
        orb.update();
        orb.render();
      });
    });
  } else {
    orbs.forEach((orb) => {
      orb.update();
      orb.render();
    });
  }



};

export default heroBlock;

