import barbajs from '@barba/core';
import {gsap} from 'gsap';

let popState = {'status': false};
window.addEventListener('popstate', () => {
  popState.status = true;
});
export const pageCleaningFunctions = [];
const crossFade = {
  name: 'bottom-overlay-color-transition',
  leave(data) {
    const header = document.querySelector('header');
    if (!header.classList.contains('active')) {
      const cover = document.querySelector('.page-transition');
      return gsap.timeline()
        .to(cover, {opacity: 1})
        .call(() => popState.status ? (popState.status = false) : window.scrollTo(0, 0))
    }
  },
  enter(data) {
    const header = document.querySelector('header');
    gsap.set(data.next.container, {opacity: 0});
    gsap.set(data.current.container, {zIndex: -1, position: 'absolute'});

    if (header.classList.contains('active')) {
      const btnMenu = document.querySelector(".menu-btn");
      return gsap.timeline()
        .set(data.next.container, {opacity: 1})
        .set(data.current.container, {opacity: 0})
        .call(() => btnMenu.click())
        .to({}, {})
    } else {
      const cover = document.querySelector('.page-transition');
      return gsap.timeline()
        .set(data.next.container, {opacity: 1})
        .set(data.current.container, {opacity: 0})
        .to(cover, {opacity: 0})
    }
  },
  afterLeave(data) {
    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
      match = regexp.exec(data.next.html);
    if (!match || !match[1]) {
      // If no body class, remove it
      document.body.setAttribute("class", "loaded");
    } else {
      // Set the new body class
      document.body.setAttribute("class", `${match[1]} loaded`);
    }
  }
};
export const barba = (reInvokableFunction) => {
  if (document.querySelector('[data-barba]')) {
    barbajs.init({
      transitions: crossFade,
      timeout: 0,
      prevent: ({el}) => el.classList?.contains('ab-item'),
    });

    barbajs.hooks.afterLeave(() => {
      // window.scrollTo(0, 0);
      window.dispatchEvent(new Event('will-leave'));
      while (pageCleaningFunctions.length) {
        pageCleaningFunctions.pop()();
      }
    });

    barbajs.hooks.beforeEnter(data => {
      // window.scrollTo(0, 0);
      reInvokableFunction(data.next.container);
    });
    // barbajs.hooks.afterEnter(({current}) => {
    //   window.scrollTo(0, 0);
    // });

  } else {
    console.log('no barbajs container');
  }
}
